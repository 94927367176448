import React from "react";

import SEO from "components/Seo";
import Header from "components/Header";
import Footer from "components/Footer";

import LayoutMain from "components/layout/layoutMain";
import LayoutPanel from 'components/layout/layoutPanel';
import heroBanner from 'images/rac/natural-gas/kleenheat-rac-logo.png';
import Image from 'components/common/Image';
import { modalContents } from "constants/modal-content";
import { LinkModal } from "kh-common-components";

function RacPage() {
	
	return (
		<>
			<SEO title="Exclusive Kleenheat offer for RAC Members | Kleenheat" 
                 titleBespoke="true"
            	 description="RAC members can enjoy bigger savings on their natural gas with Kleenheat."
                 meta={[{name:"robots", content:'noindex'}]}
        	/>
			<Header />
			<LayoutMain>
				<LayoutPanel theme="mer-theme--cyan"
							 background="mer-bg--primary-cyan"
							 padding="mer-py-lg">
					<div className="container">
						<div className="row mer-panel align-items-center justify-content-around flex-lg-column">
							<div className="mer-panel__images col-10 col-sm-6 col-lg-5 col-xl-4 my-0 pt-0">
								<img src={heroBanner} 
									className="mer-panel__image kleenheat-rac-logo"
									alt="RAC logo" />
							</div>

							<div className="col-12 col-sm-10 col-lg-8 col-xl-7 pt-0">
								<div className="content text-center mer-text--size-lg">
									<h1 className="mer-panel__title mer-typography--headline2 my-0">RAC members save more.</h1>
									<h5>We love partnering with locals to provide even better value to our customers. RAC members can enjoy added savings on natural gas and LPG cylinders.</h5>

									<div className="col-12 col-sm-10 col-lg-5 mer-panel__actions mer-button-group flex-column mx-auto mt-0">
										<a href="/rac#offers"
											title="Go to natural gas page"
											className="mer-button mer-button--primary">
											View offers
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<LayoutPanel background="mer-bg--ui-light mer-border--bottom mer-border--color-gs-lighter"
                     padding="mer-pt-lg">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-12 content text-center">
								<h2>Why choose Kleenheat?</h2>
							</div>
						</div>
						<div className="row justify-content-md-between align-items-center mer-text--size-lg">
							<div className="col-12 col-md-4 col-lg-3">
								<div className="row justify-content-center">
									<div className="col-4 col-sm-3 col-md-9 col-lg-8">
										<Image className="mer-panel__image"
											src="/icons/flowmoji/flowmoji-wa.svg"
											alt="Flowmoji Western Australia"
										/>
									</div>
									<div className="col-8 col-sm-4 col-md-12 text-md-center">
										<h5 className="my-0">WA born and bred</h5>
										<p>We've been at home in WA for over 65 years.</p>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4 col-lg-3">
								<div className="row justify-content-center">
									<div className="col-4 col-sm-3 col-md-9 col-lg-8">
										<Image className="mer-panel__image"
											src="/icons/flowmoji/flowmoji-piggybank.svg"
											alt="Flowmoji search"
										/>
									</div>
									<div className="col-8 col-sm-4 col-md-12 text-md-center px-1">
										<h5 className="my-0">Ongoing value</h5>
										<p>We provide great value gas and support our local community.</p>
									</div>
								</div>
							</div>
							<div className="col-12 col-md-4 col-lg-3">
								<div className="row justify-content-center">
									<div className="col-4 col-sm-3 col-md-9 col-lg-8">
										<Image className="mer-panel__image"
											src="/canstar-ng-23.png"
											alt="Canstar 2023"
											style={{transform: 'scale(0.8)'}}
										/>
									</div>
									<div className="col-8 col-sm-4 col-md-12 text-md-center">
										<h5 className="my-0">Award-winning service</h5>
										<p>Our local customer service team are here to help.</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</LayoutPanel>

				<section id="offers"
					className="mer-theme--light mer-bg--gs-grey-lighter mer-py-ju">
					<div className="container">

						<div className="row justify-content-center">
							<div className="col-10 col-sm-8 col-md-6 mb-3">
								<div className="content text-center">
									<h2 className="mt-2 mer-color--primary-cyan">Exclusive RAC member offers</h2>
								</div>
							</div>
						</div>
						
						<div className="row justify-content-around align-items-center">
							<div className="col-10 col-sm-7 col-md-5 col-lg-4 mr-xl-n5 ml-xl-5 col-xl-3 align-items-stretch px-0">
								<div className="row mer-card mdc-card mer-plan-card align-items-center">
									<div className="col-11 px-0">
										<h3 className="col-12 text-center my-0">Natural gas</h3>
										<div className="col-8 mx-auto">
											<Image className="mer-panel__image"
													src="/icons/flowmoji/flowmoji-discount.svg"
													alt="Percentage flowmoji" /> 
										</div>
										<div className="col-12 px-0 content text-center">
											<p className="px-0">RAC members can save an extra 3% off gas usage charges.<sup>*</sup></p>
										</div>
									</div>
									<div className="col-12 p-0 mer-border--top mer-border--color-gs-lighter">
										<div className="col-11 px-0 mx-auto">
											<h4 className="my-0">
												<a href="/rac/natural-gas"
													title="Natural gas offer details"
													className="mer-button mer-button--primary d-block text-center">
														<span className="mer-color--gs-white">Offer details</span>
												</a>
											</h4>
											<p className="mb-0 mt-2 text-center">
												<small>
													<LinkModal 
														modalContents={modalContents}
														useModal="true"
														title="RAC terms & conditions"
														linkClass="mer-text--no-wrap"
														displayContent={<span><sup>*</sup>Terms and conditions apply</span>}
														modalTitle="RAC terms & conditions"
														modalContentName='rac-ng-terms'
														okayButtonLabel="Okay"
													/>
												</small>
											</p>
										</div>
									</div>
								</div>
							</div>
							
							<div className="col-10 col-sm-7 col-md-5 col-lg-4 ml-xl-n5 mr-xl-5 col-xl-3 align-items-stretch px-0">
								<div className="row mer-card mdc-card mer-plan-card align-items-center">
									<div className="col-11 px-0">
										<h3 className="col-12 text-center my-0">LPG cylinders</h3>
										<div className="col-8 mx-auto">
											<Image className="mer-panel__image"
													src="/icons/flowmoji/flowmoji-dollar.svg"
													alt="Dollar flowmoji" />
										</div>
										<div className="col-12 px-0 content text-center">
											<p className="px-0">RAC members can enjoy a $20 credit on their second 45kg cylinder order each year.<sup>^</sup></p>
										</div>
									</div>
									<div className="col-12 p-0 mer-border--top mer-border--color-gs-lighter">
										<div className="col-11 px-0 mx-auto">
											<h4 className="my-0">
												<a href="/rac/lpg"
													title="Lpg offer details"
													className="mer-button mer-button--primary d-block text-center">
													<span className="mer-color--gs-white">Offer details</span>
												</a>
											</h4>
											<p className="mb-0 mt-2 text-center">
												<small>
													<LinkModal 
														modalContents={modalContents}
														useModal="true"
														title="Residential LPG 45kg cylinder offer"
														linkClass="mer-text--no-wrap"
														displayContent={<span><sup>^</sup>Terms and conditions apply</span>}
														modalTitle="Residential LPG 45kg cylinder offer"
														modalContentName="rac-lpg-terms"
														okayButtonLabel="Okay"
													/>
												</small>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="row justify-content-around align-items-center">
							<p className="col-12 text-center mt-4 pt-4 pb-0 mer-text--size-lg">
								<LinkModal 
									modalContents={modalContents}
									useModal="true"
									linkClass="mer-text--no-wrap"
									displayContent="Already a Kleenheat customer?"
									title="What value does Kleenheat offer RAC members?"
									modalTitle="What value does Kleenheat offer RAC members?"
									modalContentName="value-rac-kleenheat"
									okayButtonLabel="Okay"
								/>
							</p>
						</div>
					</div>
				</section>

			</LayoutMain>
			<Footer />
		</>
	)
}

export default RacPage;